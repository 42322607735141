<template>
  <v-autocomplete
    v-model="valuesList"
    v-bind="$attrs"
    :items="options"
    :item-text="itemText"
    :item-value="itemValue"
    :label="label"
    :search-input.sync="searchInput"
    multiple
    chips
    dense
    small-chips
    hide-details
    clearable
    deletable-chips
    outlined
    @change="select"
  >
    <template v-if="showToggleButton" v-slot:prepend-item>
      <v-list-item ripple @mousedown.prevent @click="toggle">
        <v-list-item-action>
          <v-icon :color="valuesList.length ? 'indigo darken-4' : ''">
            {{ icon }}
          </v-icon>
        </v-list-item-action>
        <v-list-item-content>Всі</v-list-item-content>
      </v-list-item>
      <v-divider class="mt-2" />
    </template>
    <template v-slot:item="{ active, item, attrs, on }">
      <v-list-item :class="{ 'red--text': item.status === 3 }" v-on="on" v-bind="attrs" v-slot:default="{ active }">
        <v-list-item-action>
          <v-checkbox :input-value="active"></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <span v-html="highlightText(item[itemText])"></span>
        </v-list-item-content>
      </v-list-item>
    </template>
    <template v-slot:selection="{ item, index, attrs, on }">
      <v-chip class="ma-1" small close v-on="on" v-bind="attrs" @click:close="closeChip(index)">
        <span class="chip-text">{{ item[itemText] }}</span>
      </v-chip>
    </template>
  </v-autocomplete>
</template>

<script>
import isEqual from 'lodash/isEqual'

export default {
  name: 'CommonFilter',

  props: {
    options: {
      type: Array,
      required: true,
      default: () => [],
    },
    label: {
      type: String,
      default: '',
    },
    values: {
      type: Array,
      default: () => [],
    },
    itemText: {
      type: String,
      default: 'label',
    },
    itemValue: {
      type: String,
      default: 'value',
    },
    showToggleButton: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      valuesList: [],
      searchInput: '',
    }
  },

  watch: {
    values(val, old) {
      if (!isEqual(val, old)) {
        this.valuesList = [...val]
      }
    },
  },

  computed: {
    allSelected() {
      return this.valuesList.length === this.options.length
    },
    someSelected() {
      return this.valuesList.length > 0 && !this.allSelected
    },
    icon() {
      if (this.allSelected) return 'mdi-close-box'
      if (this.someSelected) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
  },

  created() {
    this.initialize()
  },

  methods: {
    initialize() {
      if (this.values) {
        this.valuesList = [...this.values]
      }
    },

    select() {
      this.$emit('select', this.valuesList)
    },

    closeChip(index) {
      this.valuesList.splice(index, 1)
      this.select()
    },

    highlightText(text) {
      if (this.searchInput && text) {
        const regex = new RegExp(`(${this.searchInput})`, 'ig')
        return text.replace(regex, '<span class="v-list-item__mask">$1</span>')
      }
      return text
    },
    toggle() {
      this.$nextTick(() => {
        if (this.allSelected) {
          this.valuesList = []
        } else {
          this.valuesList = this.options.map((item) => item.value).slice()
        }
        this.select()
      })
    },
  },
}
</script>

<style scoped lang="scss">
.chip-text {
  width: calc(100% - 24px);
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
